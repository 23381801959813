import React, { Component } from 'react';

import { graphql } from 'gatsby';
import get from 'lodash/get';

import Layout from '../layout/Layout';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

interface Props {
  data: any;
  location: any;
}

const Content = styled.div`
  padding-top: 55px;
  background-color: #202020;
  width: 100%;
  overflow-x: hidden;
  #iframe-booking {
    width: 100%;
    height: 100vh;
    min-height: calc(100vh - 200px);
  }

  ${breakpoint('lg')`
    padding-top: 125px !important;
  `}
`;

class BookingHomepage extends Component<Props, any> {
  state = {
    bookingPath: '',
    iFrameHeight: '100vh',
  };

  componentDidMount() {
    if (typeof window !== `undefined`) {
      const loc = window.location.toString();
      const params = loc.split('?')[1];
      let bookingPath = 'https://www.nikantigolfclub.com/booking-prod/';
      if (localStorage.getItem('SHOWBOOKING') === 'dev') {
        bookingPath = window.location.origin + '/booking-dev/';
      } else if (
        localStorage.getItem('SHOWBOOKING') === 'demo' &&
        window.location.origin.includes('https://www.palo-nikanti-dev.net')
      ) {
        bookingPath = window.location.origin + '/booking-demo/';
      } else if (localStorage.getItem('SHOWBOOKING') === 'local') {
        bookingPath = 'http://localhost:3000/';
      }

      const getParameterByName = (name) => {
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(
          window.location.search
        );
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
      };

      if (getParameterByName('setBooking')) {
        if (getParameterByName('setBooking') === 'clear') {
          localStorage.removeItem('SHOWBOOKING');
        } else {
          localStorage.setItem(
            'SHOWBOOKING',
            getParameterByName('setBooking') ?? ''
          );
        }
      }

      // Pass any query params to booking iframe
      if (typeof params !== 'undefined' && bookingPath !== '') {
        this.setState({ bookingPath: bookingPath + '?' + params });
      } else if (bookingPath !== '') {
        this.setState({ bookingPath: bookingPath });
      }
    }

    window.addEventListener('message', this.handleMessagePosted, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessagePosted, false);
  }

  handleMessagePosted = (event) => {
    if (!this.isSameOrigin(event.origin)) {
      return;
    }
    if (event.data.topic === 'adjust height') {
      this.adjustHeight(+event.data.height);
    } else if (event.data.topic === 'scroll up') {
      const { x, y } = event.data.position;
      window.scrollTo(x, y);
    } else if (event.data.topic === 'payment redirection') {
      window.location.href = event.data.paymentRedirectionUrl;
    }
  };

  isSameOrigin = (origin: string) => {
    return (
      window.location.hostname === 'localhost' ||
      origin === window.location.origin
    );
  };

  adjustHeight = (iframeContentHeight: number) => {
    const iFrameHeight = iframeContentHeight + 40 + 'px';
    const clientHeight = document.documentElement.clientHeight;

    if (clientHeight > iframeContentHeight) {
      this.setState({ iFrameHeight: clientHeight + 40 + 'px' });
    } else {
      this.setState({ iFrameHeight: iFrameHeight });
    }
  };

  render() {
    const { data } = this.props;
    const lang = get(data, 'page.lang', 'en-us');

    let bookingPathWithLang = this.state.bookingPath;
    if (this.state.bookingPath.split('?').length >= 2) {
      bookingPathWithLang = this.state.bookingPath + '&lang=' + lang;
    } else {
      bookingPathWithLang = this.state.bookingPath + '?lang=' + lang;
    }

    const iframe =
      this.state.bookingPath !== '' ? (
        <iframe
          id="iframe-booking"
          style={{ height: this.state.iFrameHeight }}
          className="iframe-booking"
          src={bookingPathWithLang}
          frameBorder="0"
          title="Nikanti Booking"
        />
      ) : (
        <div
          style={{ textAlign: 'center', paddingTop: '200px', height: '50vw' }}
        ></div>
      );

    return (
      <Layout data={data} location={this.props.location}>
        <Content>{iframe}</Content>
      </Layout>
    );
  }
}

export default BookingHomepage;

export const query = graphql`
  query BookingHomepageQuery($slug: String!) {
    page: prismicBookingHomepage(id: { eq: $slug }) {
      ...BookingHomepage
    }
  }
`;
